<template>
	<div class="login">
		<h1>欢迎登陆 iBest 信息管理系统系统</h1>
		<div class="form">
			<div class="form-input">
		<input type="text" placeholder="账号:"  v-model="username"/><br>
		<input type="password" placeholder="密码:"   v-model="password"/><br>
		<input type="hidden"  v-model="inputCaptcha.key">
		<!-- <input type="text" placeholder="验证码:"  /> -->
		<div class="form-captcha">
		<input  placeholder="验证码:" v-model="captcha"> 
		<img :src="inputCaptcha.img" alt="" @click="get_captcha()">
		</div>
	</div>
		<div class="btn" @click="submit()"><a href="#">登陆</a></div>
		<!-- <el-button class="login_btn" type="primary"@click="submit()"></el-button> -->
		</div>
		
	</div>
</template>

<script>
	export default{
		data(){
			return{
					username:"",
					password:"",
					captcha:"",
					inputCaptcha:[]
				
			}
		},
		mounted() {
			this.get_captcha();
		},
		methods:{
			// 验证码
			get_captcha(){
				this.$axios.get('/api/web/captcha')
				.then(res=>{
					if(res.errcode==0){
						this.inputCaptcha=res.data
						console.log(this.inputCaptcha,"imgggggggggg")
					}
				})
			},
				
			submit(){
				this.$axios.post('/api/web/login',{
					username:this.username,
					password:this.password,
					key:this.inputCaptcha.key,
					captcha:this.captcha
					
				})
				.then(res=>{
					if (res.errcode == 0) {
					localStorage.setItem("token",res.data.token)  					 //相当于本地存储
					// this.$router.push('/')
						 window.location.href='/'
					console.log(res,"222222222222222")
										 
						
					}
					if(res.errcode==-10){
						alert('验证码有误！请重新输入')
					}
					
					if(res.code==-2){
						alert('账号或密码有误！请重新输入')
					}
				}).catch(res=>{
					console.log(res,"11111111111")
					alert('输入有误！请重新输入')
				})
			}
			
			
		}
		
	}
</script>

<style lang="scss">
	body{
		background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web3x/Apple%20TV%403x.png)no-repeat;
		background-size: cover;
	}
	.login{
		text-align: center;
		h1{
			font-size:35px;
			color: #39B7BC;
			margin: 14.4375rem auto 4rem;
		}
		
		.form{
			width: 30.375rem;
			height:23.9375rem;
			margin: 0.625rem auto;
			padding-top:3.125rem;
			background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web3x/%E7%BC%96%E7%BB%84%402x.png) no-repeat;	
			background-size: cover;
			color: #23ACB9;
			.form-input{
				// margin-top: 40px;
			}
			input{
				background: #074563;
				border: 0.125rem #00BAFF solid;
				font-size: 1.375rem;
				margin: 10px auto;
				padding: 0.625rem;
			}
			 input::-webkit-input-placeholder{
			          color:#23ACB9;
			        }
					
			.form-captcha{
				display: flex;	
				margin: 0 auto;
				width:290px;
				height:4.5rem;
				
				input{
					width:11.25rem;
				}
				img{
					width: 7rem;
					height: 3.2rem;
					margin-top: 0.625rem;
				}
				}	
					
			 .btn{
				 margin: 5px auto;
				 font-size: 1.6rem;
				 background: url(https://ibest.oss-cn-beijing.aliyuncs.com/web3x/%E7%BC%96%E7%BB%84%203%E5%A4%87%E4%BB%BD.png) no-repeat;
				 width: 9.1875rem;
				 height: 3.5rem;
				 line-height: 3.5rem;
				 background-size: 100% 100%;
				 a{
					 color: #23ACB9;
				 }
			 }
		}
	}
</style>
